// eslint-disable-next-line import/no-internal-modules
import { DefaultColorScheme } from '@mui/material/styles/experimental_extendTheme'
// eslint-disable-next-line import/no-internal-modules
import type {} from '@mui/material/themeCssVarsAugmentation'
import { InvertibleCssVarsProvider } from '@xylabs/react-invertible-theme'
import { WithChildren } from '@xylabs/react-shared'
import { useMemo } from 'react'

import { themeCssVars } from '../../../themeCssVars'

export const ForeventoryThemeProvider: React.FC<WithChildren> = ({ children }) => {
  const initialValue: DefaultColorScheme | 'system' = useMemo(() => {
    const validValues: DefaultColorScheme[] = ['light', 'dark']
    const savedValue = localStorage.getItem('mui-mode')
    if (savedValue && validValues.includes(savedValue as DefaultColorScheme)) return savedValue as DefaultColorScheme
    return 'system'
  }, [])

  return (
    <InvertibleCssVarsProvider theme={themeCssVars} defaultMode={initialValue}>
      {children}
    </InvertibleCssVarsProvider>
  )
}
