import { DarkModeRounded, LightModeRounded } from '@mui/icons-material'
import { IconButton, IconButtonProps } from '@mui/material'
import { FlexCol } from '@xylabs/react-flexbox'
import { useColorSchemeEx } from '@xylabs/react-invertible-theme'
import { useState } from 'react'

import { RotationAnimation } from '../../../../shared'

type iconColor = IconButtonProps['color']

export const DarkModeIconButton: React.FC<IconButtonProps> = (props) => {
  const { darkMode, setMode } = useColorSchemeEx()
  const [iconColor, setIconColor] = useState<iconColor>('inherit')
  const [iconColorIsSet, setIconColorIsSet] = useState(false)

  const handleHover = () => {
    setIconColor(
      darkMode ?
        iconColorIsSet ? 'inherit'
        : 'info'
      : iconColorIsSet ? 'inherit'
      : 'warning',
    )
    setIconColorIsSet(!iconColorIsSet)
  }

  const handleDarkModeChange = () => {
    setMode(darkMode ? 'light' : 'dark')
  }

  return (
    <FlexCol>
      <RotationAnimation rotation={20}>
        <IconButton color={iconColor} onClick={handleDarkModeChange} onMouseEnter={() => handleHover()} onMouseLeave={() => handleHover()} {...props}>
          {darkMode ?
            <DarkModeRounded />
          : <LightModeRounded />}
        </IconButton>
      </RotationAnimation>
    </FlexCol>
  )
}
