import { AppSettingsContextProps } from '@xyo-network/react-sdk'

export const migrateDarkMode = (appSettings: AppSettingsContextProps) => {
  // Remnant from when we mistakenly had two AppSettingsProviders
  const duplicatePref = localStorage.getItem('AppSetting|DarkMode')

  if (duplicatePref !== null && appSettings.enableDarkMode) {
    const oldDarkMode = duplicatePref !== 'false'
    appSettings.enableDarkMode(oldDarkMode)
    // clean up
    localStorage.removeItem('AppSetting|DarkMode')
  }
}
