import { Stack, Toolbar, ToolbarProps } from '@mui/material'

import { DarkModeIconButton } from './DarkModeButtonToggle'

export const ForevSystemToolbar: React.FC<ToolbarProps> = ({ children, ...props }) => {
  return (
    <Toolbar {...props}>
      <Stack direction="row" spacing={1} alignItems="center">
        {children}
        <DarkModeIconButton />
      </Stack>
    </Toolbar>
  )
}
