import { generateMnemonic } from '@scure/bip39'
// eslint-disable-next-line import/no-internal-modules
import { wordlist } from '@scure/bip39/wordlists/english'
import { WithChildren } from '@xylabs/react-shared'
import { useAppSettings, useWallet, WalletProvider, WalletProviderProps } from '@xyo-network/react-sdk'

export const Wallet: React.FC<WithChildren<WalletProviderProps>> = ({ children, ...props }) => {
  const { seedPhrase } = useAppSettings()

  const [rootWallet] = useWallet({ mnemonic: seedPhrase ?? generateMnemonic(wordlist, 256) })

  return (
    <WalletProvider rootWallet={rootWallet} {...props}>
      {children}
    </WalletProvider>
  )
}
