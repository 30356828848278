import { Payload } from '@xyo-network/payload-model'
import { AppSettingsContextProps } from '@xyo-network/react-sdk'
import { createContext } from 'react'

import { foreventoryAppSettingDefault } from './foreventoryAppSettingDefault'
import { SavedInsights } from './types'

export interface ForeventoryAppSettingsContextProps extends AppSettingsContextProps {
  accessCodes?: string[]
  clearAccessCodes?: () => void
  clearSavedInsights?: () => void
  newAddressPage?: boolean
  saveInsight?: (insight: Payload) => Promise<void>
  savedInsights?: SavedInsights
  showJSONExpanded?: boolean
  updateAccessCodes?: (newCode?: string) => void
  updateNewAddressPage?: (value: boolean) => void
  updateShowJSONExpanded?: (value: boolean) => void
}

export const ForeventoryAppSettingsContext = createContext<ForeventoryAppSettingsContextProps>(foreventoryAppSettingDefault())
