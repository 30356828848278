import { assertEx } from '@xylabs/assert'
import { AbstractWitness } from '@xyo-network/abstract-witness'
import { AnyConfigSchema } from '@xyo-network/module-model'
import { PayloadBuilder } from '@xyo-network/payload-builder'
import { Payload, PayloadSchema } from '@xyo-network/payload-model'
import { WitnessModule, WitnessParams } from '@xyo-network/witness-model'
// eslint-disable-next-line import/no-internal-modules
import { Options, parse, Parser } from 'csv-parse/browser/esm'

import { CsvImportPayload, CsvImportResultPayload, CsvImportResultSchema, CsvRecordPayload } from '../../models'
import { CsvParserWitnessConfig, CsvParserWitnessConfigSchema } from './Config'

type CsvParserWitnessAdditionalParams = {
  parserOptions?: Options
}

interface ResultState {
  csvImportPayload: CsvImportPayload
  errors: Error[]
  recordSchema?: string
  records: CsvRecordPayload[]
  source: string | null
}

export type CsvParserWitnessParams = WitnessParams<AnyConfigSchema<CsvParserWitnessConfig>, CsvParserWitnessAdditionalParams>

export class CsvParserWitness<TParams extends CsvParserWitnessParams = CsvParserWitnessParams>
  extends AbstractWitness<TParams>
  implements WitnessModule
{
  static override readonly configSchemas = [CsvParserWitnessConfigSchema]

  private _parser: Parser | null = null

  get parser() {
    return assertEx(this._parser, () => '_parser not found.  Please create one')
  }

  onEnd(
    resolve: (value: CsvImportResultPayload | PromiseLike<CsvImportResultPayload>) => void,
    reject: (reason?: Error[]) => void,
    resultState: ResultState,
  ) {
    if (resultState.errors.length > 0) {
      reject(resultState.errors)
    } else {
      const result: CsvImportResultPayload = {
        records: resultState.records,
        schema: CsvImportResultSchema,
      }
      const resultWithEndDates = this.buildStartEndDates(result, resultState.csvImportPayload.dateRangeField)
      resolve(resultWithEndDates)
    }
  }

  onError(err: Error, resultState: ResultState) {
    resultState.errors.push(err)
  }

  onRead(resultState: ResultState) {
    let record
    while ((record = this.parser.read()) !== null) {
      const csvRecordPayload: CsvRecordPayload = {
        fields: {
          ...record,
        },
        schema: resultState.recordSchema ?? PayloadSchema,
        source: resultState.source,
      }
      resultState.records.push(csvRecordPayload)
    }
  }

  parseCsv(importPayload: CsvImportPayload, resultState: ResultState): Promise<CsvImportResultPayload> {
    return new Promise((resolve, reject) => {
      this.parser.on('readable', () => this.onRead(resultState))
      this.parser.on('error', (err) => this.onError(err, resultState))
      this.parser.on('end', () => this.onEnd(resolve, reject, resultState))

      this.parser.write(importPayload.data)
      this.parser.end()
    })
  }

  protected override async observeHandler(payloads?: Payload[]) {
    if ('data' in (payloads?.[0] ?? {})) {
      const csvImportPayload = payloads?.[0] as CsvImportPayload
      const resultState = this.resultState(csvImportPayload)
      try {
        resultState.recordSchema = csvImportPayload.recordSchema
        resultState.source = await PayloadBuilder.dataHash(csvImportPayload)
        this.buildParser()
        const result = await this.parseCsv(csvImportPayload, resultState)
        return [result]
      } catch (e) {
        throw new Error(`Error parsing CSV: ${e}`)
      }
    } else {
      throw new Error('Expected first payload to have csv data but was missing')
    }
  }

  private buildParser() {
    this._parser = parse(
      this.params?.parserOptions ?? {
        columns: true,
        delimiter: ',',
      },
    )
  }

  private buildStartEndDates(result: CsvImportResultPayload, dateRangeField: string | undefined) {
    if (dateRangeField) {
      if (result.records?.[0] && result.records.at(-1)) {
        const record0 = result.records[0].fields
        const recordLast = result.records.at(-1)?.fields
        const startDate = record0[dateRangeField]
        const endDate = recordLast?.[dateRangeField]

        if (startDate) {
          result.startDate = startDate.toString()
        } else {
          console.error(`expected a value for ${dateRangeField} to use as startDate but none was found`, result.records[0])
        }
        if (endDate) {
          result.endDate = endDate.toString()
        } else {
          console.error(`expected a value for ${dateRangeField} to use as endDate but none was found`, result.records.at(-1))
        }
      } else {
        console.error('no records were found while checking for dateRangeField')
      }
    }
    return result
  }

  private resultState(csvImportPayload: CsvImportPayload): ResultState {
    return {
      csvImportPayload,
      errors: [],
      recordSchema: '',
      records: [],
      source: null,
    }
  }
}
