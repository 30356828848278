/* eslint-disable sort-keys-fix/sort-keys-fix */
export const ModuleNames = {
  GlobalNode: 'GlobalNode',
  Archivist: 'NetflixPayloadArchivist',
  XyoArchivist: 'Archivist',
  BrowserFileWitness: 'BrowserFileWitness',
  CsvParserWitness: 'CsvParserWitness',
  DataProviderArchivist: 'DataProviderArchivist',
  InsightsArchivist: 'InsightsArchivist',
  HtmlHeadMeta: 'HtmlHeadMeta',
}

export const ModuleOffsetPaths = {
  GlobalNode: '1',
  Archivist: '2',
  BrowserFileWitness: '3',
  CsvParserWitness: '4',
  DataProviderArchivist: '5',
  InsightsArchivist: '6',
  ExplorerNode: '100',
  NetflixFiles: '200',
  Web3Nfts: '300',
  HtmlHeadMeta: 'HtmlHeadMeta',
}
