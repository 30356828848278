import { Paper, styled } from '@mui/material'
import { FlexGrowRow } from '@xylabs/react-flexbox'
import { ScrollToTop } from '@xylabs/react-scroll-to-top'
import { WebAppChromeProps, WebAppPage, WebAppPageProps } from '@xyo-network/react-webapp'
import React, { PropsWithChildren, useEffect } from 'react'

import { useAppChrome } from '../context'

export interface ForevBaseSitePageProps extends WebAppPageProps {
  maxPageWidth?: WebAppChromeProps['maxWidth']
  noMenuItems?: boolean
}

export const ForevBaseSitePage: React.FC<PropsWithChildren<ForevBaseSitePageProps>> = ({ children, maxPageWidth, title, ...props }) => {
  const { changePageType } = useAppChrome()

  useEffect(() => {
    changePageType?.('site')
  }, [changePageType])

  return (
    <>
      <StyledPageRoot component={Paper}>
        <FlexGrowRow height="100%">
          <WebAppPage title={`Foreventory: ${title ?? 'Your Data, Forever'}`} maxWidth={maxPageWidth} sx={{ overflowX: 'hidden' }} {...props}>
            <ScrollToTop />
            {children}
          </WebAppPage>
        </FlexGrowRow>
      </StyledPageRoot>
    </>
  )
}

const StyledPageRoot = styled(FlexGrowRow, { name: 'StyledPageRoot' })(() => ({
  alignItems: 'stretch',
  borderRadius: 0,
  boxShadow: 'none',
  height: '100%',
}))

ForevBaseSitePage.displayName = 'ForevBaseSitePage'
