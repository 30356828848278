import { UserEventsProvider, XyoUserEventHandler } from '@xylabs/react-pixel'
import { PropsWithChildren } from 'react'

import { ForeventoryAppSettingsProvider } from '../../../shared'
import { SeedPhrase, Wallet } from './Accounts'
import { GlobalNode } from './GlobalNode'

export const AppProviders: React.FC<PropsWithChildren> = ({ children }) => (
  <UserEventsProvider userEvents={XyoUserEventHandler.get()}>
    <ForeventoryAppSettingsProvider value={{}}>
      <SeedPhrase>
        <Wallet>
          <GlobalNode>{children}</GlobalNode>
        </Wallet>
      </SeedPhrase>
    </ForeventoryAppSettingsProvider>
  </UserEventsProvider>
)
