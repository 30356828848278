import { bufferPolyfill } from '@xylabs/buffer'
import { Gtag } from '@xylabs/react-pixel'
import { BrowserRouter } from 'react-router-dom'

import { AppProviders, AppShell, ErrorReporting, RootRoutes } from './modules'

Gtag.init('G-G893EH7PP9', '', ['foreventory.com'])

bufferPolyfill()

export const App: React.FC = () => {
  return (
    <ErrorReporting>
      <AppProviders>
        <BrowserRouter>
          <AppShell>
            <RootRoutes />
          </AppShell>
        </BrowserRouter>
      </AppProviders>
    </ErrorReporting>
  )
}
