import { Typography } from '@mui/material'
import { FlexCol } from '@xylabs/react-flexbox'
import { LinkEx } from '@xylabs/react-link'

import { ForevBaseSitePage } from '../../page'

export const MovingPage: React.FC = () => {
  return (
    <ForevBaseSitePage>
      <FlexCol alignItems={'center'} justifyContent={'center'} height={'100%'}>
        <Typography textAlign={'center'} variant="h3">
          Foreventory is moving!
        </Typography>
        <Typography textAlign={'center'}>
          XYO is moving Foreventory to the XYO Operating System. You can check out the platform{' '}
          <LinkEx href="https://node.xyo.network/" target="_blank">
            here.
          </LinkEx>
        </Typography>
      </FlexCol>
    </ForevBaseSitePage>
  )
}
