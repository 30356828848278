import { CssBaseline } from '@mui/material'
import { WithChildren } from '@xylabs/react-shared'
import { Helmet } from 'react-helmet'

import { defaultShareImage } from '../../../img'
import { AppChrome, AppChromeProvider } from '../../page'
import { ForeventoryThemeProvider } from './ForeventoryThemeProvider'

export const AppShell: React.FC<WithChildren> = ({ children }) => {
  return (
    <ForeventoryThemeProvider>
      <Helmet>
        <meta property="og:image" content={defaultShareImage} />
      </Helmet>
      <CssBaseline />
      <AppChromeProvider>
        <AppChrome>{children}</AppChrome>
      </AppChromeProvider>
    </ForeventoryThemeProvider>
  )
}
