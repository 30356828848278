import { Button } from '@mui/material'
import { WithChildren } from '@xylabs/react-shared'
import { ErrorBoundary, ErrorReporterProvider } from '@xyo-network/react-sdk'
import Rollbar from 'rollbar'

const getRollbarEnvironment = () => {
  switch (document.location.hostname) {
    case 'foreventory.com': {
      return 'production'
    }
    case 'beta.foreventory.com': {
      return 'beta'
    }
    case 'insiders.foreventory.com': {
      return 'beta'
    }
    default: {
      return 'development'
    }
  }
}

export const rollbar = new Rollbar({
  accessToken: '1f88b193f2d349799dd29550e15215c5',
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: getRollbarEnvironment(),
})

const LastResortErrorPage: React.FC<{ error: Error }> = ({ error }) => {
  return (
    <>
      <h1>Oops! Something went wrong!</h1>
      <p>{`${error}`}</p>
      <Button onClick={() => (window.location.href = '/')} variant="contained">
        Homepage
      </Button>
    </>
  )
}

export const ErrorReporting: React.FC<WithChildren> = ({ children }) => (
  <ErrorReporterProvider rollbar={rollbar}>
    <ErrorBoundary
      fallbackWithError={(error) => {
        return <LastResortErrorPage error={error} />
      }}
    >
      {children}
    </ErrorBoundary>
  </ErrorReporterProvider>
)
