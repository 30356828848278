import { Promisable } from '@xylabs/promise'
import { DivinerConfig, DivinerConfigSchema, DivinerParams } from '@xyo-network/diviner'
import { AbstractDiviner } from '@xyo-network/diviner-abstract'
import { TwitterMeta } from '@xyo-network/sdk-meta'

import { ForeventoryProviderPayload } from '../Provider'
import { HtmlHeadMetaPayload } from './Payload'

export type HtmlHeadMetaDivinerConfigSchema = 'network.xyo.diviner.html.head.meta.config'
export const HtmlHeadMetaDivinerConfigSchema: HtmlHeadMetaDivinerConfigSchema = 'network.xyo.diviner.html.head.meta.config'

export type HtmlHeadMetaDivinerConfig = DivinerConfig<{
  schema: HtmlHeadMetaDivinerConfigSchema
  templates?: {
    description?: string
    image?: string
    title?: string
  }
  twitter?: {
    card?: TwitterMeta['card']
  }
}>

export type HtmlHeadMetaDivinerParams = DivinerParams<HtmlHeadMetaDivinerConfig>

export const fillTemplate = (template?: string, vars?: ForeventoryProviderPayload) => {
  return template ? new Function('return `' + template + '`;').call(vars) : undefined
}

export class HtmlHeadMetaDiviner extends AbstractDiviner<HtmlHeadMetaDivinerParams> {
  static override readonly configSchemas = [HtmlHeadMetaDivinerConfigSchema, DivinerConfigSchema]
  override divineHandler(providers: ForeventoryProviderPayload[]): Promisable<HtmlHeadMetaPayload[]> {
    return providers.map((provider) => {
      const title = fillTemplate(this.config.templates?.title ?? provider.name, provider)
      const description = fillTemplate(this.config.templates?.description, provider)
      const image = fillTemplate(this.config.templates?.image ?? provider.images?.share, provider)
      const result: HtmlHeadMetaPayload = {
        og: {
          description,
          image,
          title,
        },
        schema: 'network.xyo.html.head.meta',
        title,
        twitter: {
          card: this.config.twitter?.card ?? 'summary_large_image',
          description,
          image,
          title,
        },
      }
      return result
    })
  }
}
