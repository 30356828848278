import { Toolbar, ToolbarProps, Typography } from '@mui/material'
import { FlexGrowRow } from '@xylabs/react-flexbox'
import { useColorSchemeEx } from '@xylabs/react-invertible-theme'
import { LinkEx } from '@xylabs/react-link'

import { logoIconBlack, logoIconWhite } from '../../../../img'

export const ForevSiteContextToolbar: React.FC<ToolbarProps> = ({ ...props }) => {
  const { darkMode } = useColorSchemeEx()

  return (
    <Toolbar {...props}>
      <LinkEx to="/" lineHeight={0} style={{ textDecoration: 'none' }} color={'inherit'}>
        <FlexGrowRow width="100%">
          <img src={darkMode ? logoIconWhite : logoIconBlack} height={40} />
          <Typography variant="h6" fontWeight={300} paddingRight={0.5}>
            Foreventory
          </Typography>
          <Typography variant="subtitle1" fontWeight={300}>
            <sup>Beta</sup>
          </Typography>
        </FlexGrowRow>
      </LinkEx>
    </Toolbar>
  )
}
