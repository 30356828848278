import { AppBarEx, AppBarExProps } from '@xylabs/react-appbar'

import { ForevSiteContextToolbar } from './ForevSiteContextToolbar'
import { ForevSystemToolbar } from './ForevSystemToolbar'

export const ForevSiteBar: React.FC<AppBarExProps> = ({ ...props }) => {
  return (
    <>
      <AppBarEx
        elevation={0}
        contextToolbar={<ForevSiteContextToolbar></ForevSiteContextToolbar>}
        systemToolbar={<ForevSystemToolbar />}
        onClick={(event) => event.stopPropagation()}
        {...props}
      ></AppBarEx>
    </>
  )
}
