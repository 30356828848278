import { WithChildren } from '@xylabs/react-shared'
import { DefaultSeedPhrase, useAppSettings } from '@xyo-network/react-sdk'

export const SeedPhrase: React.FC<WithChildren> = ({ children }) => {
  const { changeSeedPhrase, seedPhrase } = useAppSettings()

  return (
    <DefaultSeedPhrase seedPhrase={seedPhrase} changeSeedPhrase={changeSeedPhrase} hideDefaultSeedPhraseMessage>
      {children}
    </DefaultSeedPhrase>
  )
}
