import { Route, Routes, RoutesProps } from 'react-router-dom'

import { MovingPage } from './pages'

export const RootRoutes: React.FC<RoutesProps> = (props) => {
  return (
    <Routes {...props}>
      <Route path="/" element={<MovingPage />} />
      <Route path="*" element={<MovingPage />} />
    </Routes>
  )
}
