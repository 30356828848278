import { PayloadBuilder } from '@xyo-network/payload-builder'
import { Payload } from '@xyo-network/payload-model'
import { AppSettingsProvider, AppSettingsProviderProps, useAppSettings } from '@xyo-network/react-sdk'
import React, { useCallback, useState } from 'react'

import { ForeventoryAppSettingsContext } from './Context'
import { foreventoryAppSettingDefault } from './foreventoryAppSettingDefault'
import { useMigrateSettings } from './migrations'
import { ForeventoryAppSettingsStorage } from './Storage'

interface ForeventoryAppSettingsProviderProps extends AppSettingsProviderProps {
  storage?: ForeventoryAppSettingsStorage
}

const ForeventoryAppSettingsProviderInner: React.FC<ForeventoryAppSettingsProviderProps> = ({
  value = foreventoryAppSettingDefault(),
  storage = new ForeventoryAppSettingsStorage(),
  children,
  ...props
}) => {
  useMigrateSettings()

  const appSettings = useAppSettings()

  const [savedInsights, setSavedInsights] = useState(storage.savedInsights)
  const [accessCodes, setAccessCodes] = useState(storage.accessCodes)
  const [showJSONExpanded, setShowJSONExpanded] = useState(storage.showJSONExpanded)
  const [newAddressPage, setNewAddressPage] = useState(storage.newAddressPage)

  const clearSavedInsights = useCallback(() => {
    setSavedInsights({})
  }, [])

  const saveInsight = useCallback(
    async (payload: Payload) => {
      const hash = await PayloadBuilder.dataHash(payload)
      setSavedInsights((previouslySavedInsights) => {
        previouslySavedInsights[payload.schema] = hash
        storage.savedInsights = previouslySavedInsights
        return { ...previouslySavedInsights }
      })
    },
    [storage],
  )

  const clearAccessCodes = useCallback(() => {
    setAccessCodes([])
  }, [])

  const updateAccessCodes = useCallback(
    (newAccessCode?: string) => {
      if (newAccessCode) {
        setAccessCodes((previousCodes) => {
          if (previousCodes.includes(newAccessCode)) {
            return previousCodes
          } else {
            const newState = [...previousCodes, newAccessCode]
            storage.accessCodes = newState
            return newState
          }
        })
      }
    },
    [storage],
  )

  const updateShowJSONExpanded = useCallback((value: boolean) => {
    setShowJSONExpanded(value)
  }, [])

  const updateNewAddressPage = useCallback(
    (value: boolean) => {
      setNewAddressPage(value)
      storage.newAddressPage = value
    },
    [storage],
  )

  return (
    <ForeventoryAppSettingsContext.Provider
      {...props}
      value={{
        ...value,
        ...appSettings,
        accessCodes,
        clearAccessCodes,
        clearSavedInsights,
        newAddressPage,
        saveInsight,
        savedInsights,
        showJSONExpanded,
        updateAccessCodes,
        updateNewAddressPage,
        updateShowJSONExpanded,
      }}
    >
      {children}
    </ForeventoryAppSettingsContext.Provider>
  )
}

export const ForeventoryAppSettingsProvider: React.FC<ForeventoryAppSettingsProviderProps> = ({
  storage = new ForeventoryAppSettingsStorage(),
  ...props
}) => {
  return (
    <AppSettingsProvider value={{}} storage={storage}>
      <ForeventoryAppSettingsProviderInner storage={storage} {...props} />
    </AppSettingsProvider>
  )
}
