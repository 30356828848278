import { Divider } from '@mui/material'
import { FlexGrowCol } from '@xylabs/react-flexbox'
import { WithChildren } from '@xylabs/react-shared'
import { XyoFooter } from '@xyo-network/react-sdk'
import { WebAppChrome } from '@xyo-network/react-webapp'

import { useAppChrome } from '../context'
import { ForevSiteBar } from './Header'

export const AppChrome: React.FC<WithChildren> = ({ children }) => {
  const { appChromeProps } = useAppChrome()

  return (
    <WebAppChrome
      appName={'Foreventory'}
      footer={
        <FlexGrowCol alignItems="stretch">
          <XyoFooter dynamicHeight />
        </FlexGrowCol>
      }
      appbar={<ForevSiteBar />}
      {...appChromeProps}
    >
      <Divider orientation="horizontal" flexItem sx={{ display: { md: 'flex', xs: 'none' } }} />
      {children}
    </WebAppChrome>
  )
}
