import { experimental_extendTheme as extendTheme } from '@mui/material'

declare module '@mui/material/styles' {
  interface PaletteOptions {
    dataProviderColor: {
      main: string
    }
  }

  interface Palette {
    dataProviderColor: {
      main: string
    }
  }
}

const lightPalette = {
  background: { default: '#EEEEEE', paper: '#fafafa' },
  dataProviderColor: {
    main: '#000',
  },
  error: {
    main: '#fd4a6b',
  },
  primary: {
    main: '#651FC7',
  },
  secondary: {
    main: '#3992F0',
  },
  success: {
    main: '#6bcd23',
  },
  warning: {
    main: '#ffc805',
  },
}
const darkPalette = {
  background: { default: '#161616', paper: '#1E1E1E' },
  dataProviderColor: {
    main: '#fff',
  },
  primary: {
    main: '#afaff9',
  },
  secondary: {
    main: '#90c2f8',
  },
  text: {
    disabled: '#a5acdb',
    primary: '#f7f8fc',
    secondary: '#daddf2',
  },
}

export const themeCssVars = extendTheme({
  colorSchemes: {
    dark: {
      palette: darkPalette,
    },
    light: {
      palette: lightPalette,
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.vars.palette.background.paper,
          boxShadow: 'none',
          color: theme.vars.palette.common.black,
          [theme.getColorSchemeSelector('dark')]: {
            color: theme.vars.palette.common.white,
          },
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          //This is to get rid of the extra padding at the end of Card Content that misaligns padding when there's only 1 item
          '&:last-child': {
            paddingBottom: '16px',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 400,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: 'xl',
        },
      },
    },
  },
  spacing: 12,
  typography: {
    allVariants: {
      fontFamily: 'Roboto Flex, sans-serif',
    },
    body1: {
      fontSize: '16px',
      fontWeight: 300,
    },
    button: {
      fontSize: '1rem',
      fontWeight: 400,
      textTransform: 'capitalize',
    },
    fontWeightBold: 700,
    fontWeightLight: 300,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    h1: {
      fontSize: '3.2rem',
    },
    h2: {
      fontSize: '2.7rem',
    },
    h3: {
      fontSize: '2.24rem',
    },
    h4: {
      fontSize: '2rem',
    },
    h5: {
      fontSize: '1.5rem',
    },
    h6: {
      fontSize: '1.2rem',
    },
    subtitle1: {
      fontWeight: 300,
      opacity: '50%',
      textTransform: 'uppercase',
    },
    subtitle2: {
      opacity: '50%',
    },
  },
})
