import { AppSettingsStorage } from '@xyo-network/react-sdk'

import { foreventoryAppSettingDefault } from './foreventoryAppSettingDefault'
import { ForeventoryAppSettingSlug } from './Slug'
import { SavedInsights } from './types'

export class ForeventoryAppSettingsStorage extends AppSettingsStorage {
  constructor() {
    super('ForeventoryAppSetting', foreventoryAppSettingDefault())
  }

  get accessCodes() {
    return this.getObject(ForeventoryAppSettingSlug.AccessCodes)
  }

  set accessCodes(value: string[]) {
    this.setObject(ForeventoryAppSettingSlug.AccessCodes, value)
  }

  get newAddressPage() {
    return this.getBoolean(ForeventoryAppSettingSlug.NewAddressPage)
  }

  set newAddressPage(value: boolean) {
    this.setBoolean(ForeventoryAppSettingSlug.NewAddressPage, value)
  }

  get savedInsights() {
    return this.getObject(ForeventoryAppSettingSlug.SavedInsights)
  }

  set savedInsights(value: SavedInsights) {
    this.setObject(ForeventoryAppSettingSlug.SavedInsights, value)
  }

  get showJSONExpanded() {
    return this.getBoolean(ForeventoryAppSettingSlug.ShowJSONExpanded)
  }

  set showJSONExpanded(value: boolean) {
    this.setBoolean(ForeventoryAppSettingSlug.ShowJSONExpanded, value)
  }
}
