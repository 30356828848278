import { useAppSettings } from '@xyo-network/react-sdk'
import { useEffect } from 'react'

import { migrateDarkMode } from './lib'

export const useMigrateSettings = () => {
  const appSettings = useAppSettings()

  useEffect(() => {
    migrateDarkMode(appSettings)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
