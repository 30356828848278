import { ForeventoryAppSettingSlug } from './Slug'

export const foreventoryAppSettingDefault = (): Record<string, unknown> => {
  return {
    [ForeventoryAppSettingSlug.SavedInsights]: {},
    [ForeventoryAppSettingSlug.AccessCodes]: [],
    [ForeventoryAppSettingSlug.ShowJSONExpanded]: true,
    [ForeventoryAppSettingSlug.NewAddressPage]: true,
  }
}
