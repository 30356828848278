import { isLocalhost } from '@xylabs/react-shared'

const betaPrefixes = ['beta', 'insiders']

export const ProdUrl = 'https://api.archivist.xyo.network'
export const BetaUrl = 'https://beta.api.archivist.xyo.network'

export const getApiDomain = () => {
  if (process.env.REACT_APP_API_DOMAIN !== undefined) {
    return process.env.REACT_APP_API_DOMAIN as string
  }
  // uncomment for development against archivist on localhost
  if (isLocalhost()) {
    return 'http://localhost:8080'
  }
  if (betaPrefixes.some((prefix) => location.hostname.startsWith(prefix))) {
    return BetaUrl
  }
  return ProdUrl
}
