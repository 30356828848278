import { usePromise } from '@xylabs/react-promise'
import { IndexedDbArchivist } from '@xyo-network/archivist-indexeddb'
import { ManifestWrapper, PackageManifest, PackageManifestPayload } from '@xyo-network/manifest'
import { ModuleFactoryLocator } from '@xyo-network/module-factory-locator'
import { NodeInstance } from '@xyo-network/node-model'
import { NodeProvider } from '@xyo-network/react-modules'
import { useWallet } from '@xyo-network/react-sdk'
import { WalletInstance } from '@xyo-network/wallet-model'
import { PropsWithChildren, useState } from 'react'

import { CsvParserWitness, HtmlHeadMetaDiviner, ModuleOffsetPaths, updatedManifestApiDomain } from '../../../../lib'
import globalNodeManifest from './global-node-manifest.json'

//all this is here to make this a true singleton
let _globalNode: Promise<NodeInstance> | undefined
let _rootWalletAddress: string | undefined

const createGlobalNode = async (wallet: WalletInstance) => {
  try {
    const nodeWallet = await wallet.derivePath(ModuleOffsetPaths.GlobalNode)
    const updatedGlobalNodeManifest = updatedManifestApiDomain(globalNodeManifest as PackageManifest)
    const locator = new ModuleFactoryLocator()
    locator.register(CsvParserWitness)
    locator.register(HtmlHeadMetaDiviner)
    locator.register(IndexedDbArchivist)
    const manifest = new ManifestWrapper(updatedGlobalNodeManifest as PackageManifestPayload, nodeWallet, locator)
    const [node] = await manifest.loadNodes()

    console.log(`GlobalNode (should only run once) Node: ${node?.address}`)

    return node
  } catch (e) {
    const error = e as Error
    console.error(`Error creating global node: ${error.message}`)
    console.error(`Error creating global node: ${error.stack}`)
    throw new Error(`Error creating global node: ${error.message}`)
  }
}

export const GlobalNode: React.FC<PropsWithChildren> = ({ children }) => {
  const [wallet] = useWallet()
  const [globalNode, setGlobalNode] = useState<NodeInstance>()

  usePromise(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async () => {
      if (wallet) {
        if (_rootWalletAddress === wallet.address) {
          if (_globalNode) {
            return
          }
        } else {
          //clear the old value since a new root address was received
          _globalNode = undefined
        }
        _rootWalletAddress = wallet.address
        _globalNode = _globalNode ?? createGlobalNode(wallet)
      }
      const node = await _globalNode
      setGlobalNode(node)
    },
    [wallet],
  )

  return <NodeProvider node={globalNode}>{children}</NodeProvider>
}
