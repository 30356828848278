import { WithChildren } from '@xylabs/react-shared'
import { useState } from 'react'

import { AppChromeContext } from './Context'
import { PageTypes, PartialWebAppChromeProps } from './State'

export const AppChromeProvider: React.FC<WithChildren<PartialWebAppChromeProps>> = ({ children, ...props }) => {
  const [appChromeProps, setAppChromeProps] = useState<PartialWebAppChromeProps>(props)
  const [pageType, setPageType] = useState<PageTypes>()

  const replaceAppChromeProps = (newAppChromeProps?: PartialWebAppChromeProps) => {
    if (newAppChromeProps) {
      setAppChromeProps(() => ({
        ...newAppChromeProps,
      }))
    }
  }

  const updateAppChromeProps = (newAppChromeProps?: PartialWebAppChromeProps) => {
    if (newAppChromeProps) {
      setAppChromeProps((previous) => ({
        previous,
        ...newAppChromeProps,
      }))
    }
  }

  const changePageType = (newPageType?: PageTypes) => {
    if (newPageType) {
      setPageType(newPageType)
    }
  }

  return (
    <AppChromeContext.Provider value={{ appChromeProps, changePageType, pageType, provided: true, replaceAppChromeProps, updateAppChromeProps }}>
      {children}
    </AppChromeContext.Provider>
  )
}
